<template>
  <div :style="{minHeight: height+'px'}">
    <pie-chart
      v-if="displayType === 'pie'"
      :chart-data="chartData"
      :chart-id="`${chartId}-pie`"
      :height="height"
      :colors="colors"
      legendPosition="right"
    ></pie-chart>
    <bar-chart
      v-else
      :chart-data="chartData"
      :chart-id="`${chartId}-bar`"
      :colors="colors"
      :height="height"
      format="time"
      :transpose="true"
      :showLabel="true"
      :legend="false"
    ></bar-chart>
  </div>
</template>

<script>
import PieChart from '@/components/chart/PieChart'
import BarChart from '@/components/chart/BarChart'
import { severityColor } from '@/utils'

export default {
  name: 'AlertSeverityChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    durationData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 170
    },
    displayType: {
      type: String,
      default: 'pie'
    }
  },
  components: {
    PieChart,
    BarChart
  },
  methods: {
    colors (v) {
      return severityColor(v)
    }
  }
}
</script>
