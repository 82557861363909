<template>
  <pie-chart
    :chart-data="chartData"
    :chart-id="chartId"
    :height="height"
    :flip-page="false"
    legend-position="right"
  ></pie-chart>
</template>

<script>
import PieChart from '@/components/chart/PieChart'

export default {
  name: 'SourceTypeChart',
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartId: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      default: 170
    }
  },
  components: {
    PieChart
  }
}
</script>
