<template>
  <a-row :gutter="16">
    <!-- 设备类型占比图表 || 设备总数 -->
    <a-col
      :span="8"
      style="margin-bottom: 16px"
    >
      <a-card
        size="small"
        :bodyStyle="bodyStyle"
      >
        <div slot="title" class="pdf-split-flag">设备数量统计</div>
        <div class="pdf-split-flag" style="height: 200px; overflow: hidden; padding-top: 12px">
          <source-type-chart
            v-if="detail[`count_of_${sourceType === 'network_device' ? 'device' : sourceType}_type`]"
            :chartData="detail[
              `count_of_${sourceType === 'network_device' ? 'device' : sourceType}_type`
            ].map(item => {
              return {
                name: $t(`${sourceType}_type.${item.name}`),
                value: item.value
              }
            })"
            :chart-id="`count-of-${sourceType}-type-chart`"
          ></source-type-chart>

          <div class="simple-number">{{detail.count}}</div>
        </div>
      </a-card>
    </a-col>
    <!-- 入流量TOP -->
    <a-col
      v-if="detail['top_of_net_if_in']"
      :sm="24"
      :lg="8"
      style="margin-bottom: 16px"
    >
      <a-card
        class="pdf-split-flag"
        size="small"
        title="TOP 5 统计 - 入流量 (bps)"
        :bodyStyle="bodyStyle"
      >
        <alert-info-top
          class="net-top-in"
          style="min-height: 210px; margin-top: -10px;"
          :img-height="16"
          :show-unit="false"
          :detail="detail['top_of_net_if_in'].map(item => {
            return {
              ...item,
              value: humanizeValue(item.value)
            }
          })"
        ></alert-info-top>
      </a-card>
    </a-col>
    <!-- 出流量 -->
    <a-col
      v-if="detail['top_of_net_if_out']"
      :sm="24"
      :lg="8"
      style="margin-bottom: 16px"
    >
      <a-card
        class="pdf-split-flag"
        size="small"
        title="TOP 5 统计 - 出流量 (bps)"
        :bodyStyle="bodyStyle"
      >
        <alert-info-top
          class="net-top-out"
          style="min-height: 210px; margin-top: -10px;"
          :img-height="16"
          :show-unit="false"
          :detail="detail['top_of_net_if_out'].map(item => {
            return {
              ...item,
              value: humanizeValue(item.value)
            }
          })"
        ></alert-info-top>
      </a-card>
    </a-col>
    <!-- CPU使用率TOP -->
    <a-col
      v-if="detail['top_of_cpu']"
      :sm="24"
      :lg="8"
      style="margin-bottom: 16px"
    >
      <a-card
        class="pdf-split-flag"
        size="small"
        title="TOP 5 统计 - CPU 使用率 (%)"
        :bodyStyle="bodyStyle"
      >
        <cpu-usage-top
          style="min-height: 210px; margin-top: -10px;"
          :top-data="detail['top_of_cpu']"
        >
        </cpu-usage-top>
      </a-card>
    </a-col>
    <!-- 内存使用率TOP -->
    <a-col
      v-if="detail['top_of_memory']"
      :sm="24"
      :lg="8"
      style="margin-bottom: 16px"
    >
      <a-card
        class="pdf-split-flag"
        size="small"
        title="TOP 5 统计 - 内存使用率 (%)"
        :bodyStyle="bodyStyle"
      >
        <cpu-usage-top
          style="min-height: 210px; margin-top: -10px;"
          :top-data="detail['top_of_memory']"
        >
        </cpu-usage-top>
      </a-card>
    </a-col>
    <!-- 磁盘使用率TOP -->
    <a-col
      v-if="detail['top_of_filesystem']"
      :sm="24"
      :lg="8"
      style="margin-bottom: 16px"
    >
      <a-card
        class="pdf-split-flag"
        size="small"
        title="TOP 5 统计 - 磁盘使用率 (%)"
        :bodyStyle="bodyStyle"
      >
        <cpu-usage-top
          style="min-height: 210px; margin-top: -10px;"
          :top-data="detail['top_of_filesystem']"
        >
        </cpu-usage-top>
      </a-card>
    </a-col>
    <!-- 告警相关统计 -->
    <template v-if="detail['alert']['count']">
      <!-- 告警等级分布图表 -->
      <a-col
        v-if="detail['alert']['count_of_severity']"
        :sm="24"
        :lg="8"
        style="margin-bottom: 16px"
      >
        <a-card
          class="pdf-split-flag"
          size="small"
          title="告警统计 - 类型统计"
          :bodyStyle="bodyStyle"
        >
          <div style="height: 200px; overflow: hidden; padding-top: 1px">
              <alert-severity-chart
              :chartData="detail['alert'][
                  'count_of_severity'
                ].map(item => {
                  return {
                    name: $t(`alert_severity.${item.name}`),
                    value: item.value
                  }
                })"
              :chart-id="`${sourceType}-alert-severity-chart`"
              displayType="pie"
            ></alert-severity-chart>
          </div>
        </a-card>
      </a-col>
      <!-- 设备告警次数TOP -->
      <a-col
        v-if="detail['alert']['top_of_source']"
        :sm="24"
        :lg="8"
        style="margin-bottom: 16px"
      >
        <a-card
          class="pdf-split-flag"
          size="small"
          title="TOP 5 统计 - 设备告警次数(次)"
          :bodyStyle="bodyStyle"
        >
          <alert-info-top
            style="min-height: 210px; margin-top: -10px;"
            :img-height="16"
            :detail="detail['alert']['top_of_source']"
          ></alert-info-top>
        </a-card>
      </a-col>
      <!-- 告警内容次数TOP -->
      <a-col
        v-if="detail['alert']['top']"
        :sm="24"
        :lg="8"
        style="margin-bottom: 16px"
      >
        <a-card
          class="pdf-split-flag"
          size="small"
          title="TOP 5 统计 - 告警事件次数(次)"
          :bodyStyle="bodyStyle"
        >
          <alert-info-top
            style="min-height: 210px; margin-top: -10px;"
            :img-height="16"
            :detail="detail['alert']['top']"
          ></alert-info-top>
        </a-card>
      </a-col>
      <!-- 告警等级时效 -->
      <a-col
        v-if="detail['alert']['duration_of_severity']"
        :sm="24"
        :lg="8"
        style="margin-bottom: 16px"
      >
        <a-card
          class="pdf-split-flag"
          size="small"
          title="告警统计 - 时效统计"
          :bodyStyle="bodyStyle"
        >
          <alert-severity-chart
            :chartData="detail['alert'][
              'duration_of_severity'
            ].map(item => {
              return {
                name: $t(`alert_severity.${item.name}`),
                value: item.value
              }
            })"
            :chart-id="`${sourceType}-alert-severity-chart`"
            style="min-height: 200px;"
            displayType="bar"
          ></alert-severity-chart>
        </a-card>
      </a-col>
      <!-- 告警列表 -->
      <a-col
        v-if="detail['alert']['alerts']"
        :span="24"
        style="margin-bottom: 16px">
        <a-card
          size="small"
          :bodyStyle="bodyStyle"
        >
          <div class="pdf-split-flag" slot="title">告警列表</div>
          <alert-simple-table
            class="split-table"
            :data-source="detail['alert']['alerts']"
            :scroll="tableScroll"
          ></alert-simple-table>
        </a-card>
      </a-col>
    </template>
    <!-- 设备列表 -->
    <a-col
      v-if="detail[sourceType === 'os' ? `${sourceType}es` : `${sourceType}s`]"
      :span="24"
      style="margin-bottom: 16px"
    >
      <a-card
        size="small"
      >
        <div slot="title" class="pdf-split-flag">{{ $t(`source_type.${sourceType}`) }}列表</div>
        <source-table
          class="split-table"
          :scroll="tableScroll"
          :columnsAll="columns"
          :data-source="detail[sourceType === 'os' ? `${sourceType}es` : `${sourceType}s`]"
          style="min-height: 200px;"
        ></source-table>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { humanizeTime, humanizeValue } from '@/utils'
import SourceTypeChart from './SourceTypeChart'
import AlertSeverityChart from './AlertSeverityChart'
import SourceTable from './SourceTable.vue'
import AlertInfoTop from '@/components/top/AlertInfoTop.vue'
import AlertSimpleTable from '@/components/table/AlertSimpleTable.vue'
import CpuUsageTop from '@/components/top/CpuUsageTop.vue'

export default {
  name: 'SourceStatisticsModule',
  components: {
    SourceTypeChart,
    AlertSeverityChart,
    SourceTable,
    AlertInfoTop,
    AlertSimpleTable,
    CpuUsageTop
  },
  props: {
    sourceType: {
      type: String,
      default: 'network_device'
    },
    detail: {
      type: Object,
      default: () => { return {} }
    },
    tableScroll: {
      type: Object,
      default: () => { return { y: 215.5 } }
    },
    bodyStyle: {
      type: Object
    }
  },
  computed: {
    columns () {
      if (this.sourceType === 'network_device') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'monitor_address',
            title: 'IP 地址',
            width: 160
          },
          {
            dataIndex: 'device_type',
            title: '设备类型',
            width: 160,
            scopedSlots: {
              customRender: 'deviceType'
            }
          }
        ]
      }
      if (this.sourceType === 'os') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'monitor_address',
            title: 'IP 地址',
            width: 160
          },
          {
            dataIndex: 'cpu',
            title: 'CPU (核)',
            width: 100,
            scopedSlots: {
              customRender: 'cpu'
            }
          },
          {
            dataIndex: 'memory',
            title: '内存 (GB)',
            width: 100,
            scopedSlots: {
              customRender: 'memory'
            }
          },
          {
            dataIndex: 'os_type',
            title: '类型',
            width: 120,
            scopedSlots: {
              customRender: 'osType'
            }
          }
        ]
      }
      if (this.sourceType === 'server') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'monitor_address',
            title: 'IP 地址',
            width: 160
          }
        ]
      }
      if (this.sourceType === 'storage') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'monitor_address',
            title: 'IP 地址',
            width: 160
          }
        ]
      }
      if (this.sourceType === 'middleware') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'middleware_type',
            title: '中间件类型',
            width: 160,
            scopedSlots: {
              customRender: 'middlewareType'
            }
          },
          {
            dataIndex: 'version',
            title: '版本',
            width: 160,
            ellipsis: true
          }
        ]
      }
      if (this.sourceType === 'database') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'database_type',
            title: '数据库类型',
            width: 160,
            scopedSlots: {
              customRender: 'databaseType'
            }
          },
          {
            dataIndex: 'version',
            title: '版本',
            width: 160,
            ellipsis: true
          }
        ]
      }
      if (this.sourceType === 'domain') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'registrar',
            title: '注册商',
            width: 200,
            scopedSlots: {
              customRender: 'registrar'
            }
          },
          {
            dataIndex: 'registered_at',
            title: '注册时间',
            width: 180
          },
          {
            dataIndex: 'expires_at',
            title: '到期时间',
            width: 180
          }
        ]
      }
      if (this.sourceType === 'ssl_certificate') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'issuer',
            title: '签发机构',
            width: 180,
            scopedSlots: {
              customRender: 'issuer'
            }
          },
          {
            dataIndex: 'issued_at',
            title: '签发时间',
            width: 180
          },
          {
            dataIndex: 'expires_at',
            title: '到期时间',
            width: 180
          }
        ]
      }
      if (this.sourceType === 'hypervisor') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'monitor_address',
            title: 'IP 地址',
            width: 160
          },
          {
            dataIndex: 'cpu',
            title: 'CPU (核)',
            width: 100,
            scopedSlots: {
              customRender: 'cpu'
            }
          },
          {
            dataIndex: 'memory',
            title: '内存 (GB)',
            width: 100,
            scopedSlots: {
              customRender: 'memory'
            }
          },
          {
            dataIndex: 'version',
            title: '版本',
            width: 160,
            ellipsis: true
          }
        ]
      }
      if (this.sourceType === 'application') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 200,
            scopedSlots: {
              customRender: 'name'
            }
          },
          {
            dataIndex: 'description',
            title: '描述',
            width: 200,
            ellipsis: true,
            scopedSlots: {
              customRender: 'description'
            }
          },
          {
            dataIndex: 'monitor_address',
            title: 'IP 地址',
            width: 160,
            scopedSlots: {
              customRender: 'monitorAddress'
            }
          },
          {
            dataIndex: 'version',
            title: '版本',
            width: 160,
            ellipsis: true
          }
        ]
      }
      if (this.sourceType === 'site') {
        return [
          {
            dataIndex: 'name',
            title: '名称',
            width: 180
          },
          {
            dataIndex: 'delay',
            title: '监控频率',
            width: 140
          },
          {
            dataIndex: 'protocol',
            title: '协议类型',
            width: 140,
            scopedSlots: {
              customRender: 'protocol'
            }
          },
          {
            dataIndex: 'retries',
            title: '重试次数',
            width: 140
          },
          {
            dataIndex: 'url',
            title: 'URL',
            width: 180
          }
        ]
      }
      return []
    }
  },
  methods: {
    humanizeTime,
    humanizeValue
  }
}
</script>
<style lang="less" scoped>
:deep(.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar) {
  display: none !important;
  border: none !important;
}
</style>
