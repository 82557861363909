<template>
  <div id="alert-info">
    <div
      class="alert-info-item"
      v-for="(item, index) in detail"
      :key="item.name"
    >
      <div
        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
      >
        <img
          class="item-img"
          v-if="index < 3"
          :height="imgHeight"
          :src="require(`@/assets/icons/fire/fire${index + 1}.svg`)"
        />
        <span
          class="item-no"
          v-else
          >0{{ index + 1 }}</span
        >
        <a-tooltip :title="item.name">
          <span v-if="isUrl" style="cursor: pointer;" @click="showDetail(item)">{{ item.name }}</span>
          <span v-else style="cursor: default;">{{ item.name }}</span>
        </a-tooltip>
      </div>
      <div class="item-value">
        {{ item.value }}
        <span
          v-if="showUnit"
          style="
            font-size: 12px;
            font-weight: 400;
            margin-left: -4px;
            vertical-align: text-top;
          "
          >次</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'AlertInfoTop',
  props: {
    detail: {
      type: Array,
      required: true
    },
    isUrl: {
      type: Boolean,
      default: false
    },
    showUnit: {
      type: Boolean,
      default: true
    },
    imgHeight: {
      type: Number,
      default: 24
    },
    paramKey: {
      type: String
    }
  },
  methods: {
    showDetail (item) {
      const params = {
        datetime_from: moment().subtract(6, 'day').format('YYYY-MM-DD HH:mm'),
        datetime_to: moment().format('YYYY-MM-DD HH:mm')
      }
      if (this.paramKey) params[this.paramKey] = item.id ? item.id : item.name
      this.$router.push({
        name: 'AlertHistory',
        params
      })
    }
  }
}
</script>

<style lang="less">
#alert-info {
  .alert-info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    background: #f7f8f9;
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    color: #3a3f46;
    line-height: 20px;
    padding: 12px 18px;
    &:last-of-type {
      margin: 0;
    }
    .item-img {
      margin: 0 26px 0 0;
    }
    .item-no {
      font-size: 14px;
      font-weight: 400;
      color: #686e7e;
      line-height: 20px;
      display: inline-block;
      text-align: center;
      margin-right: 26px;
      width: 24px;
    }
    .item-value {
      font-size: 18px;
      color: #096dd9;
      line-height: 25px;
      white-space: nowrap;
    }
  }
}
</style>
