<template>
  <div class="service-report">
    <a-spin :spinning="spinning" style="min-height: 400px">
      <a-row :gutter="16" id="pdfDom">
        <header
          :style="{
            fontSize: '24px',
            fontWeight: 600,
            color: '#096dd9',
            lineHeight: '42px',
            textAlign: 'center',
            paddingTop: '40px'
          }"
        >
          {{ detail.name }}
        </header>
        <div v-if="detail.name" style="text-align: center; font-size: 14px">
            统计周期：{{ `${detail.datetime_from || '-'} ~  ${detail.datetime_to || '-'}` }}
        </div>
        <div style="margin-top: 24px" v-if="detail.statistic['overview']">
          <!-- 基本概况 -->
          <div
            id="overview"
            class="pdf-split-flag small-module-title"
          >
            <a-icon type="profile"></a-icon>
            <span> 基本概况</span>
          </div>
          <a-row :gutter="16" v-if="detail.name">
            <a-col :sm="24" :lg="14" style="margin-bottom: 16px">
              <a-card
                size="small"
                class="pdf-split-flag"
                title="资源数量统计"
                :bodyStyle="bodyStyle"
                style="padding-bottom: 4px;"
              >
                <a-col :span="6">
                  <a-statistic
                    :value="detail.statistic['application']['count']"
                    title="应用"
                    style="text-align: center; margin-top: 92px"
                    :value-style="{
                      fontSize: '48px',
                      fontWeight: 500,
                      lineHeight: '80px',
                      color: '#1890ff',
                      textAlign: 'center'
                    }"
                  >
                  </a-statistic>
                </a-col>
                <a-col :span="18" style="padding-right: 16px">
                  <a-row
                    :gutter="16"
                    style="
                      font-size: 32px;
                      text-align: center;
                    "
                  >
                    <a-divider orientation="left" style="font-weight: 400; margin: 2px 0 4px 0;">资源</a-divider>
                    <a-col :span="8" style="color: #90f489">
                      <source-icon source-type="os"></source-icon>
                      <a-statistic
                        title="操作系统"
                        :value="detail.statistic['os']['count']"
                        :value-style="valueStyle"
                      ></a-statistic>
                    </a-col>
                    <a-col :span="8" style="color: #f9f871">
                      <source-icon source-type="middleware"></source-icon>
                      <a-statistic
                        title="中间件"
                        :value="detail.statistic['middleware']['count']"
                        :value-style="valueStyle"
                      ></a-statistic>
                    </a-col>
                    <a-col :span="8" style="color: #ffc75f">
                      <source-icon source-type="database"></source-icon>
                      <a-statistic
                        title="数据库"
                        :value="detail.statistic['database']['count']"
                        :value-style="valueStyle"
                      ></a-statistic>
                    </a-col>
                  </a-row>
                  <a-row
                    :gutter="16"
                    style="font-size: 32px; text-align: center"
                  >
                    <a-divider orientation="left" style="font-weight: 400; margin: 6px 0 4px 0;">依赖</a-divider>
                    <a-col :span="8" style="color: #ff9671">
                      <source-icon source-type="domain"></source-icon>
                      <a-statistic
                        title="域名"
                        :value="detail.statistic['domain']['count']"
                        :value-style="valueStyle"
                      ></a-statistic>
                    </a-col>
                    <a-col :span="8" style="color: #ff6f91">
                      <source-icon source-type="site"></source-icon>
                      <a-statistic
                        title="站点"
                        :value="detail.statistic['site']['count']"
                        :value-style="valueStyle"
                      ></a-statistic>
                    </a-col>
                    <a-col :span="8" style="color: #d65db1">
                      <source-icon
                        source-type="ssl_certificate"
                      ></source-icon>
                      <a-statistic
                        title="SSL 证书"
                        :value="detail.statistic['ssl_certificate']['count']"
                        :value-style="valueStyle"
                      ></a-statistic>
                    </a-col>
                  </a-row>
                </a-col>
              </a-card>
            </a-col>
            <a-col :sm="24" :lg="10" style="margin-bottom: 16px">
              <a-card
                size="small"
                class="pdf-split-flag"
                title="告警统计"
                :bodyStyle="bodyStyle"
              >
                <div style="padding: 30px 30px 16px 0">
                  <pie-chart
                    :chart-data="
                      detail.statistic['overview']['alert'][
                        'count_of_source_type'
                      ].map(item => {
                        return {
                          name: $t(`source_type.${item.name}`),
                          value: item.value
                        }
                      })
                    "
                    :chart-id="`overview-count-of-source-type-chart`"
                    :height="240"
                    legendPosition="right"
                  ></pie-chart>
                </div>
              </a-card>
            </a-col>
            <!-- 站点图表 -->
            <a-col
              :span="24"
              v-if="detail.statistic['site']['sites'].length"
              style="margin-bottom: 16px; padding: 0"
            >
              <div
                v-for="(site, index) in detail.statistic['site']['sites']"
                :key="site.id"
              >
                <a-divider
                  class="pdf-split-flag"
                  orientation="left"
                  style="padding: 0 8px"
                >
                  {{ site.name }} 站点详情
                </a-divider>

                <div
                  v-for="(item,title) in detail.statistic['site']['monitor'][index]['history']"
                  :key="`${item.name}-${title}`"
                >
                  <a-col
                    :sm="24"
                    :lg="12"
                    :xl="8"
                    style="margin-bottom: 8px"
                  >
                    <monitor-chart-card-with-data
                      class="pdf-split-flag"
                      :title="getMonitorTitle(title)"
                      :chartData="item"
                      :chartId="`${site.name}-${title}-chart`"
                      :datetime-range="datetimeRange"
                    ></monitor-chart-card-with-data>
                  </a-col>
                </div>
              </div>
            </a-col>
          </a-row>

          <!-- 应用 -->
          <div
            id="application"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['application']"
          >
            <a-icon type="profile"></a-icon>
            <span> 应用</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['application'] && detail.statistic['application'].count"
            source-type="application"
            :detail="detail.statistic['application']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['application'] && !detail.statistic['application'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 操作系统 -->
          <div
            id="os"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['os']"
          >
            <a-icon type="profile"></a-icon>
            <span> 操作系统</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['os'] && detail.statistic['os'].count"
            source-type="os"
            :detail="detail.statistic['os']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['os'] && !detail.statistic['os'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 中间件 -->
          <div
            id="middleware"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['middleware']"
          >
            <a-icon type="profile"></a-icon>
            <span> 中间件</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['middleware'] && detail.statistic['middleware'].count"
            source-type="middleware"
            :detail="detail.statistic['middleware']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['middleware'] && !detail.statistic['middleware'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 数据库 -->
          <div
            id="database"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['database']"
          >
            <a-icon type="profile"></a-icon>
            <span> 数据库</span>
          </div>
          <source-statistics-module
            v-if="detail.statistic['database'] && detail.statistic['database'].count"
            source-type="database"
            :detail="detail.statistic['database']"
            :table-scroll="tableScroll"
            :body-style="bodyStyle"
          >
          </source-statistics-module>
          <div v-else-if="detail.statistic['database'] && !detail.statistic['database'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

          <!-- 依赖 -->
          <div
            id="rely"
            class="pdf-split-flag small-module-title"
            v-if="detail.statistic['domain'] || detail.statistic['site'] || detail.statistic['ssl_certificate']"
          >
            <a-icon type="profile"></a-icon>
            <span> 依赖</span>
          </div>
          <div style="padding: 8px 24px 0 24px;">
            <!-- 域名 -->
            <a-divider v-if="detail.statistic['domain']" id="domain" style="font-weight: 500; font-size: 14px; margin: 0 0 12px 0;">域名</a-divider>
            <source-statistics-module
              v-if="detail.statistic['domain'] && detail.statistic['domain'].count"
              source-type="domain"
              :detail="detail.statistic['domain']"
              :table-scroll="tableScroll"
              :body-style="bodyStyle"
            >
            </source-statistics-module>
            <div v-else-if="detail.statistic['domain'] && !detail.statistic['domain'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

            <!-- ssl -->
            <a-divider v-if="detail.statistic['ssl_certificate']" id="ssl_certificate" style="font-weight: 500; font-size: 14px; margin: 12px 0;">SSL 证书</a-divider>
            <source-statistics-module
              v-if="detail.statistic['ssl_certificate'] && detail.statistic['ssl_certificate'].count"
              source-type="ssl_certificate"
              :detail="detail.statistic['ssl_certificate']"
              :table-scroll="tableScroll"
              :body-style="bodyStyle"
            >
            </source-statistics-module>
            <div v-else-if="detail.statistic['ssl_certificate'] && !detail.statistic['ssl_certificate'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>

            <!-- 站点 -->
            <a-divider v-if="detail.statistic['site']"  id="site" style="font-weight: 500; font-size: 14px; margin: 12px 0;">站点</a-divider>
            <source-statistics-module
              v-if="detail.statistic['site'] && detail.statistic['site'].count"
              source-type="site"
              :detail="detail.statistic['site']"
              :table-scroll="tableScroll"
              :body-style="bodyStyle"
            >
            </source-statistics-module>
            <div v-else-if="detail.statistic['site'] && !detail.statistic['site'].count" class="pdf-split-flag" style="padding: 0 24px; color: rgba(0,0,0,.85)">暂无资源。</div>
          </div>
        </div>
        <div v-else style="height: 400px; position: relative; overflow: hidden;">
          <empty-component
            :imgUrl="require('@/assets/images/info_empty.png')"
            :body-style="{
              height: '220px',
              marginTop: '70px'
            }"
            :description-style="{
              marginTop: '-12px',
              marginRight: '8px',
              color: 'rgba(0,0,0,.55)'
            }"
            description="暂无业务报表数据"
          >
            <a-button slot="customContent" type="primary">创建新报表</a-button>
          </empty-component>
        </div>
      </a-row>
      <div v-if="detail.statistic['overview']" class="tool-box"
        :style="{
          right: !displayAnchor ? '-104px' : 0
        }"
      >
        <div style="position: absolute; left: -21px; top: 24px; background: #ffffffee;  border-radius: 6px 0 0 6px; box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;">
          <a-button type="link" @click="displayAnchor = !displayAnchor" style="padding: 3px;">
            <a-icon v-if="displayAnchor"  type="double-right" />
            <a-icon v-else type="double-left" />
          </a-button>
        </div>
        <a-button
          @click="exportPdf"
          type="primary"
          style="margin: 16px 0 -6px 16px;"
        >
          导出
        </a-button>
        <a-anchor
          :affix="false"
          :wrapperStyle="{ background: 'transparent', marginTop: '24px' }"
          :offsetTop="80"
        >
          <a-anchor-link href="#overview" title="基本概况" />
          <a-anchor-link href="#application" title="应用" />
          <a-anchor-link href="#os" title="操作系统" />
          <a-anchor-link href="#middleware" title="中间件" />
          <a-anchor-link href="#database" title="数据库" />
          <a-anchor-link href="#rely" title="依赖">
            <a-anchor-link
              href="#domain"
              title="域名"
            />
            <a-anchor-link
              href="#ssl_certificate"
              title="SSL 证书"
            />
            <a-anchor-link
              href="#site"
              title="站点"
            />
          </a-anchor-link>
        </a-anchor>
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { getServiceReport } from '@/api/report'
import PdfLoader from '@/utils/htmlToPdf'
import SourceStatisticsModule from '@/components/report/SourceStatisticsModule.vue'
import PieChart from '@/components/chart/PieChart'
import SourceIcon from '@/components/icon/SourceIcon'
import MonitorChartCardWithData from '@/components/card/MonitorChartCardWithData'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'ServiceReportDetail',
  components: {
    PieChart,
    SourceIcon,
    MonitorChartCardWithData,
    SourceStatisticsModule,
    EmptyComponent
  },
  data () {
    return {
      spinning: true,
      displayAnchor: true,
      valueStyle: {
        color: 'rgba(0,0,0,.55)'
      },
      detail: {
        id: this.$route.params.id,
        name: '',
        statistic: {}
      },
      datetimeRange: undefined,
      bodyStyle: {
        overflow: 'hidden',
        background: '#fff',
        borderRadius: '8px'
      },
      tableScroll: { y: 215.5 }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.spinning = true
      getServiceReport(this.detail.id)
        .then(res => {
          this.detail = res.data
          this.datetimeRange = [
            moment(this.detail.datetime_from),
            moment(this.detail.datetime_to)
          ]
        })
        .finally(() => {
          this.spinning = false
        })
    },
    exportPdf () {
      this.spinning = true
      this.tableScroll = {}
      this.$nextTick(() => {
        const ele = document.getElementById('pdfDom')
        const pdf = new PdfLoader(ele, this.detail.name, 'pdf-split-flag')
        pdf.outPdf().then(() => {
          this.tableScroll = { y: 215.5 }
          this.spinning = false
        })
      })
    },
    getMonitorTitle (v) {
      if (v === '2xx') return '2xx 请求数'
      if (v === '4xx') return '4xx 请求数'
      if (v === '5xx') return '5xx 请求数'
      if (v === '95th_request_time') return '95% 请求时间'
      if (v === 'body_bytes_sent') return '发送字节数'
      if (v === 'requests') return '请求数'
    }
  }
}
</script>
<style lang="less">
.service-report {
  background: #fff;
  padding: 0 28px 40px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  position: relative;

  .ant-card {
    box-shadow: none !important;
  }

  .simple-number {
    text-align: center;
    font-size: 56px;
    line-height: 148px;
  }

  // 模块标题
  .small-module-title {
    height: 35px;
    background: #e4f1ff;
    border-radius: 8px;
    color: #096dd9;
    padding: 0 16px;
    margin-bottom: 16px;
    margin-top: 24px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

    // 流量TOP
  #alert-info {
    .alert-info-item {
      height: 32px;
      margin-bottom: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      padding: 6px 12px;
      .item-img {
        margin: 0 14px 2px 0;
      }
      .item-no {
        margin-right: 6px;
      }
      .item-value {
        font-size: 14px;
        color: #1162ba;
        line-height: 20px;
      }
    }
  }
  .net-top-in {
    .item-value {
      color: #188630 !important;
    }
  }
  .net-top-out {
    .item-value {
      color: #f39119 !important;
    }
  }

  // 使用率TOP
  #cpu-usage-top {
    .usage-top-container {
      height: 32px;
      overflow: hidden;
      margin-bottom: 10px;
    }
  }

  .ant-table-thead > tr > th {
    padding: 8px;
    background: #fafafa !important;
  }
  .ant-table-tbody > tr > td {
    padding: 8px;
  }
  .outPdf-thead th {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    background: #fafafa !important;
  }

  // 导航
  .tool-box {
    position: fixed;
    bottom: 8vh;
    width: 120px;
    min-height: 100px;
    padding-bottom: 10px;
    padding-left: 12px;
    background: #ffffffee;
    border-radius: 6px 0 0 6px;
    transition: .3s;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px;
  }
}
.ant-select > div {
  background: #fff !important;
}
</style>
