var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"span":8}},[_c('a-card',{attrs:{"size":"small","bodyStyle":_vm.bodyStyle}},[_c('div',{staticClass:"pdf-split-flag",attrs:{"slot":"title"},slot:"title"},[_vm._v("设备数量统计")]),_c('div',{staticClass:"pdf-split-flag",staticStyle:{"height":"200px","overflow":"hidden","padding-top":"12px"}},[(_vm.detail[`count_of_${_vm.sourceType === 'network_device' ? 'device' : _vm.sourceType}_type`])?_c('source-type-chart',{attrs:{"chartData":_vm.detail[
            `count_of_${_vm.sourceType === 'network_device' ? 'device' : _vm.sourceType}_type`
          ].map(item => {
            return {
              name: _vm.$t(`${_vm.sourceType}_type.${item.name}`),
              value: item.value
            }
          }),"chart-id":`count-of-${_vm.sourceType}-type-chart`}}):_vm._e(),_c('div',{staticClass:"simple-number"},[_vm._v(_vm._s(_vm.detail.count))])],1)])],1),(_vm.detail['top_of_net_if_in'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - 入流量 (bps)","bodyStyle":_vm.bodyStyle}},[_c('alert-info-top',{staticClass:"net-top-in",staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"img-height":16,"show-unit":false,"detail":_vm.detail['top_of_net_if_in'].map(item => {
          return {
            ...item,
            value: _vm.humanizeValue(item.value)
          }
        })}})],1)],1):_vm._e(),(_vm.detail['top_of_net_if_out'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - 出流量 (bps)","bodyStyle":_vm.bodyStyle}},[_c('alert-info-top',{staticClass:"net-top-out",staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"img-height":16,"show-unit":false,"detail":_vm.detail['top_of_net_if_out'].map(item => {
          return {
            ...item,
            value: _vm.humanizeValue(item.value)
          }
        })}})],1)],1):_vm._e(),(_vm.detail['top_of_cpu'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - CPU 使用率 (%)","bodyStyle":_vm.bodyStyle}},[_c('cpu-usage-top',{staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"top-data":_vm.detail['top_of_cpu']}})],1)],1):_vm._e(),(_vm.detail['top_of_memory'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - 内存使用率 (%)","bodyStyle":_vm.bodyStyle}},[_c('cpu-usage-top',{staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"top-data":_vm.detail['top_of_memory']}})],1)],1):_vm._e(),(_vm.detail['top_of_filesystem'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - 磁盘使用率 (%)","bodyStyle":_vm.bodyStyle}},[_c('cpu-usage-top',{staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"top-data":_vm.detail['top_of_filesystem']}})],1)],1):_vm._e(),(_vm.detail['alert']['count'])?[(_vm.detail['alert']['count_of_severity'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"告警统计 - 类型统计","bodyStyle":_vm.bodyStyle}},[_c('div',{staticStyle:{"height":"200px","overflow":"hidden","padding-top":"1px"}},[_c('alert-severity-chart',{attrs:{"chartData":_vm.detail['alert'][
                'count_of_severity'
              ].map(item => {
                return {
                  name: _vm.$t(`alert_severity.${item.name}`),
                  value: item.value
                }
              }),"chart-id":`${_vm.sourceType}-alert-severity-chart`,"displayType":"pie"}})],1)])],1):_vm._e(),(_vm.detail['alert']['top_of_source'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - 设备告警次数(次)","bodyStyle":_vm.bodyStyle}},[_c('alert-info-top',{staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"img-height":16,"detail":_vm.detail['alert']['top_of_source']}})],1)],1):_vm._e(),(_vm.detail['alert']['top'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"TOP 5 统计 - 告警事件次数(次)","bodyStyle":_vm.bodyStyle}},[_c('alert-info-top',{staticStyle:{"min-height":"210px","margin-top":"-10px"},attrs:{"img-height":16,"detail":_vm.detail['alert']['top']}})],1)],1):_vm._e(),(_vm.detail['alert']['duration_of_severity'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"sm":24,"lg":8}},[_c('a-card',{staticClass:"pdf-split-flag",attrs:{"size":"small","title":"告警统计 - 时效统计","bodyStyle":_vm.bodyStyle}},[_c('alert-severity-chart',{staticStyle:{"min-height":"200px"},attrs:{"chartData":_vm.detail['alert'][
            'duration_of_severity'
          ].map(item => {
            return {
              name: _vm.$t(`alert_severity.${item.name}`),
              value: item.value
            }
          }),"chart-id":`${_vm.sourceType}-alert-severity-chart`,"displayType":"bar"}})],1)],1):_vm._e(),(_vm.detail['alert']['alerts'])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"span":24}},[_c('a-card',{attrs:{"size":"small","bodyStyle":_vm.bodyStyle}},[_c('div',{staticClass:"pdf-split-flag",attrs:{"slot":"title"},slot:"title"},[_vm._v("告警列表")]),_c('alert-simple-table',{staticClass:"split-table",attrs:{"data-source":_vm.detail['alert']['alerts'],"scroll":_vm.tableScroll}})],1)],1):_vm._e()]:_vm._e(),(_vm.detail[_vm.sourceType === 'os' ? `${_vm.sourceType}es` : `${_vm.sourceType}s`])?_c('a-col',{staticStyle:{"margin-bottom":"16px"},attrs:{"span":24}},[_c('a-card',{attrs:{"size":"small"}},[_c('div',{staticClass:"pdf-split-flag",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.$t(`source_type.${_vm.sourceType}`))+"列表")]),_c('source-table',{staticClass:"split-table",staticStyle:{"min-height":"200px"},attrs:{"scroll":_vm.tableScroll,"columnsAll":_vm.columns,"data-source":_vm.detail[_vm.sourceType === 'os' ? `${_vm.sourceType}es` : `${_vm.sourceType}s`]}})],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }