<template>
  <a-table
    class="custom-source-table"
    size="small"
    :columns="columnsAll"
    :data-source="dataSource"
    :pagination="false"
    row-key="id"
    :scroll="scroll"
    :rowClassName="()=>'pdf-split-flag'"
    style="min-height: 167px; margin-top: -4px;"
  >
    <template slot="name" slot-scope="record">
      <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
    </template>

    <template slot="protocol" slot-scope="text">
      <a-tag color="blue">{{ text.toUpperCase() }}</a-tag>
    </template>

    <template slot="deviceType" slot-scope="record">
      {{ $t(`network_device_type.${record}`) }}
    </template>
  </a-table>
</template>

<script>
export default {
  name: 'SourceTable',
  props: {
    dataSource: {
      type: Array
    },
    scroll: {
      type: Object,
      default: () => {
        return { y: 167 }
      }
    },
    columnsAll: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>
<style scoped>
.custom-source-table >>> .ant-table-header {
  background: #fafafa!important;
}
</style>
