<template>
  <a-card
    :body-style="{ padding: 4 }"
    :bordered="false"
    :head-style="{ borderBottom: 0 }"
    size="small"
    :title="title"
  >
    <line-chart
      :height="280"
      :chartData="chartData"
      :datetime-range="datetimeRange"
      :chartId="chartId"
    ></line-chart>
  </a-card>
</template>

<script>
import LineChart from './modules/LineChart'

export default {
  name: 'MonitorChartCardWithData',
  props: {
    chartData: {
      type: Array,
      requires: true
    },
    chartId: {
      type: String
    },
    title: {
      type: String
    },
    datetimeRange: {
      type: Array
    }
  },
  components: {
    LineChart
  }
}
</script>
