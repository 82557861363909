<template>
  <a-spin :spinning="spinning">
    <div :id="chartId" :style="{ height: `${height}px` }"></div>
  </a-spin>
</template>

<script>
import moment from 'moment'
import DataSet from '@antv/data-set'
import { Chart } from '@antv/g2'
import { humanizeValue } from '@/utils'

const chart = {}

export default {
  name: 'LineChart',
  props: {
    height: {
      type: Number,
      default: 240
    },
    chartData: {
      type: Array
    },
    chartId: {
      type: String
    },
    datetimeRange: {
      type: Array
    }
  },
  data () {
    return {
      spinning: false
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      appendPadding: [8, 8, 0, 0]
    })
    chart[this.chartId].scale({
      timestamp: {
        type: 'time',
        mask: 'MM-DD HH:mm'
      },
      value: {
        min: 0,
        nice: true,
        alias: '值',
        formatter: value => humanizeValue(value)
      }
    })
    chart[this.chartId].tooltip({
      showCrosshairs: true
    })
    chart[this.chartId].axis('timestamp', {
      tickLine: null,
      line: null,
      grid: null,
      label: {
        style: {
          fill: '#808080'
        }
      }
    })

    chart[this.chartId].axis('value', {
      grid: {
        line: {
          style: {
            stroke: '#cccccc',
            strokeOpacity: 0.8,
            lineWidth: 1,
            lineDash: [4]
          }
        }
      },
      label: {
        style: {
          fill: '#808080'
        }
      }
    })
    chart[this.chartId].option('slider', {
      trendCfg: {
        isArea: false
      },
      formatter: (value) => {
        return moment(value).format('MM-DD HH:mm')
      }
    })
    this.render()
  },
  methods: {
    render () {
      const ds = new DataSet({
        state: {
          start: this.datetimeRange[0].valueOf(),
          end: this.datetimeRange[1].valueOf()
        }
      })
      const dv = ds.createView('origin').source(this.chartData)
      dv.transform({
        type: 'filter',
        callback: function callback (obj) {
          return obj.timestamp >= ds.state.start && obj.timestamp <= ds.state.end
        }
      })

      chart[this.chartId].data(dv.rows)
      chart[this.chartId]
        .line()
        .position('timestamp*value')
        .color('#096DD9')
      chart[this.chartId]
        .area()
        .position('timestamp*value')
        .color('l(90) 0:#096DD9 1:#DAE9F9')
      if (this.chartData.length !== 0) {
        let max = this.chartData[0]
        let min = this.chartData[0]
        for (const datum of this.chartData) {
          if (datum.value > max.value) max = datum
          if (datum.value < min.value) min = datum
        }
        chart[this.chartId].annotation().dataMarker({
          point: {
            style: {
              stroke: '#ff9671'
            }
          },
          direction: 'downward',
          top: true,
          position: [max.timestamp, max.value],
          text: {
            content: `峰值: ${humanizeValue(max.value)}`
          }
        })
        if (max.timestamp !== min.timestamp) {
          chart[this.chartId].annotation().dataMarker({
            point: {
              style: {
                stroke: '#ff9671'
              }
            },
            top: true,
            position: [min.timestamp, min.value],
            text: {
              content: `谷值: ${humanizeValue(min.value)}`
            }
          })
        }
      }
      chart[this.chartId].render()
    }
  }
}
</script>
