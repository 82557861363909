<template>
  <a-table
    class="custom-alert-table"
    :rowClassName="()=>'pdf-split-flag'"
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    row-key="id"
    :scroll="scroll"
    size="small"
    style="min-height: 167px; margin-top: -4px;"
  >
    <template slot="name" slot-scope="record">
      <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
    </template>
    <template slot="severity" slot-scope="record">
      <alert-severity-tag :severity="record"></alert-severity-tag>
    </template>
    <template slot="recoveredAt" slot-scope="record">
      <span v-if="record">{{record}}</span>
      <span v-else>-</span>
    </template>
    <template slot="sourceType" slot-scope="record">{{
      $t(`source_type.${record}`)
    }}</template>
    <template slot="source" slot-scope="record">
      <a-tooltip v-if="record" placement="topLeft" :title="record.name">{{
        record.name
      }}</a-tooltip>
      <span v-else>-</span>
    </template>
    <template slot="recovered" slot-scope="record">
      <boolean-tag
        :value="record"
        false-name="未恢复"
        true-name="已恢复"
      ></boolean-tag>
    </template>
  </a-table>
</template>

<script>
import AlertSeverityTag from '@/components/tag/AlertSeverityTag'
import BooleanTag from '@/components/tag/BooleanTag'

export default {
  name: 'AlertSimpleTable',
  components: {
    AlertSeverityTag,
    BooleanTag
  },
  props: {
    dataSource: {
      type: Array
    },
    scroll: {
      type: Object,
      default: () => {
        return { y: 167 }
      }
    }
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'severity',
          title: '严重性',
          width: 80,
          scopedSlots: {
            customRender: 'severity'
          }
        },
        {
          dataIndex: 'source',
          title: '资源名称',
          width: 200,
          scopedSlots: {
            customRender: 'source'
          }
        },
        {
          dataIndex: 'triggered_at',
          title: '触发时间',
          width: 180
        },
        {
          dataIndex: 'recovered_at',
          title: '恢复时间',
          width: 180,
          scopedSlots: {
            customRender: 'recoveredAt'
          }
        }
        // {
        //   dataIndex: 'recovered',
        //   title: '状态',
        //   width: 100,
        //   scopedSlots: {
        //     customRender: 'recovered'
        //   }
        // }
      ]
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach(column => {
        sum += column.width
      })
      return sum
    }
  }
}
</script>
<style scoped>
.custom-alert-table >>> .ant-table-header {
  background: #fafafa!important;
}
</style>
