<template>
  <a-tag :color="color">{{ $t(`alert_severity.${severity}`) }}</a-tag>
</template>

<script>
export default {
  name: 'AlertSeverityTag',
  props: {
    severity: {
      type: String,
      required: true
    }
  },
  computed: {
    color () {
      if (this.severity === 'disaster') return 'red'
      else if (this.severity === 'high') return 'orange'
      else if (this.severity === 'average') return 'blue'
      else if (this.severity === 'warning') return 'cyan'
      else return 'blue'
    }
  }
}
</script>
