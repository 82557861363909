<template>
  <a-tag :color="value ? trueColor : falseColor">
    {{ value ? trueName : falseName }}
  </a-tag>
</template>

<script>
import { greenColor, redColor } from '@/utils/const'

export default {
  name: 'BooleanTag',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    trueName: {
      type: String,
      default: '正常'
    },
    trueColor: {
      type: String,
      default: greenColor
    },
    falseName: {
      type: String,
      default: '停用'
    },
    falseColor: {
      type: String,
      default: redColor
    }
  }
}
</script>
